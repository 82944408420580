import React from "react";
import { Link } from "gatsby";
import classnames from "classnames";

import Logo from "../svg/logo.svg";
import Hamburger from "../svg/menu.svg";

import "../scss/header.scss";
import "../scss/header-sticky.scss";

const HeaderHeight = 114;

export default class Header extends React.Component {
    state = {
        mobileMenuOpen: false,
        top: 0,
        max: HeaderHeight,
        isTouch: true,
    };

    static defaultProps = {
        hasMenu: true,
    };

    componentDidMount() {
        // if the page itself has sticky element
        // then the header should behave non sticky
        if (this.props.hasStickyElement) return;

        this.scroll();
        window.addEventListener("scroll", this.scroll, true);
        this.setState({
            isTouch: "ontouchstart" in document.documentElement,
        });
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.scroll, true);
    }

    scroll = () => {
        const { max } = this.state;

        const top = window.pageYOffset || document.documentElement.scrollTop;

        let newMax = 0;

        // wichever is higher
        newMax = Math.max(top, max);

        // max should never be greater than top +
        newMax = top + HeaderHeight < newMax ? top + HeaderHeight : newMax;

        // max should never be smaller than HeaderHeight
        newMax = Math.max(newMax, HeaderHeight);

        this.setState({
            top,
            max: newMax,
        });
    };

    render() {
        const { hasStickyElement, hasMenu } = this.props;
        const { mobileMenuOpen, top, max, isTouch } = this.state;

        const isSticky = !isTouch && !hasStickyElement;

        return (
            <React.Fragment>
                <section
                    className={classnames("blue", "header-bar", {
                        "mobile-menu-open": mobileMenuOpen,
                        sticky: isSticky,
                    })}
                    style={{
                        top: isSticky && Math.min(max - top - 114, 0),
                    }}
                >
                    <div className="header">
                        <Link className="home" to="/">
                            <Logo />
                        </Link>

                        {hasMenu && (
                            <>
                                <div className="menu">
                                    <ul>
                                        <li>
                                            <Link
                                                className="item"
                                                activeClassName="active"
                                                to="/nl/retailers/"
                                            >
                                                Retailers
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="item"
                                                activeClassName="active"
                                                to="/nl/merken/"
                                            >
                                                Merken
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="item"
                                                activeClassName="active"
                                                to="/nl/winkelgebieden/"
                                            >
                                                Winkelgebieden
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="item"
                                                activeClassName="active"
                                                to="/nl/tarieven/"
                                            >
                                                Tarieven
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="item"
                                                activeClassName="active"
                                                to="/nl/over-ons/"
                                            >
                                                Over ons
                                            </Link>
                                        </li>
                                        <li>
                                            <a
                                                className="btn btn-login"
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                href="https://www.zupr.nl/admin/"
                                            >
                                                Login
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <button
                                    className="mobile-menu-button"
                                    onClick={() =>
                                        this.setState({
                                            mobileMenuOpen: !mobileMenuOpen,
                                        })
                                    }
                                >
                                    <Hamburger />
                                </button>
                            </>
                        )}
                    </div>
                </section>
                <div className="header-dummy" />
            </React.Fragment>
        );
    }
}
