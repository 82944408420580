import React from "react";
import CookieConsent from "react-cookie-consent";

const Consent = () => (
    <CookieConsent
        location="bottom"
        buttonText="Accepteren"
        declineButtonText="Weigeren"
        cookieName="gatsby-gdpr-google-tagmanager"
        containerClasses="cookie-consent"
        flipButtons={true}
        buttonClasses="btn-medium success"
        declineButtonClasses="btn-medium btn-light"
        disableStyles={true}
        enableDeclineButton
        expires={150}
    >
        <h4>Cookies op zupr.io</h4>
        <p>
            We gebruiken enkel essenti&euml;le cookies om deze website te laten
            werken. We willen graag aanvullende cookies plaatsen, zodat we uw
            instellingen kunnen onthouden, begrijpen hoe mensen de service
            gebruiken en verbeteringen kunnen aanbrengen. Bekijk hier ons{" "}
            <a href="/nl/cookiebeleid/">Cookiebeleid</a> en de{" "}
            <a href="/nl/algemene-voorwaarden/">Algemene voorwaarden</a>.
        </p>
    </CookieConsent>
);

export default Consent;
