import React from 'react'
import { Link } from 'gatsby'

import Logo from '../svg/logo.svg'
import Smallstreet from '../svg/small-street.svg'
import Facebook from '../svg/facebook.svg'
import Twitter from '../svg/twitter.svg'
import Linkedin from '../svg/linkedin.svg'

export default ({ title }) => (
    <section className="footer">
        <div className="footer-image">
            <Smallstreet />
        </div>
        <div className="inner">
            <div>
                <h5>Zupr B.V.</h5>
                <ul>
                    {/* <li>Helperpark 276-8</li>
                    <li>9723 ZA Groningen</li>
                    <li><a href="https://www.google.com/maps/dir/?api=1&amp;destination=Oude+Boteringestraat+21,9712GC&amp;travelmode=driving" target="_blank" rel="noopener noreferrer">Plan route</a></li>
                    <li>&nbsp;</li> */}
                    <li className="email">
                        <a href="mailto:info@zupr.nl">info@zupr.nl</a>
                    </li>
                    <li className="telephone">
                        <a href="tel:+31854011306">+31 (0)85 40 11 300</a>
                    </li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>
                        <a className="footer-link facebook" rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/zuprcompany/">
                            <Facebook />
                        </a>
                        <a className="footer-link twitter" rel="noopener noreferrer" target="_blank" href="https://twitter.com/Zupr">
                            <Twitter />
                        </a>
                        <a className="footer-link linkedin" rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/company/zupr/">
                            <Linkedin />
                        </a>
                    </li>
                </ul>
            </div>
            <div>
                <h5>Onze oplossing</h5>
                <ul>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="/nl/retailers/">Retailers</Link>
                    </li>
                    <li>
                        <Link to="/nl/merken/">Merken</Link>
                    </li>
                    <li>
                        <Link to="/nl/winkelgebieden/">Winkelgebieden</Link>
                    </li>
                    <li>
                        <Link to="/nl/tarieven/">Tarieven</Link>
                    </li>
                    <li>
                        <Link to="/nl/over-ons/">Over ons</Link>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                        <Link to="/nl/sitemap/">Sitemap</Link>
                    </li>
                </ul>
            </div>
            <div>
                <h5>Legal</h5>
                <ul>
                    <li>
                        <Link to="/nl/cookiebeleid/">Cookiebeleid</Link>
                    </li>
                    <li>
                        <Link to="/nl/algemene-voorwaarden/">Algemene voorwaarden</Link>
                    </li>
                    <li>
                        <Link to="/nl/gebruiksvoorwaarden/">Gebruikersvoorwaarden</Link>
                    </li>
                </ul>
            </div>
            <div className="copyright">
                <Link to="/" className="logo">
                    <Logo />
                </Link>
                <h6>Maak uw producten lokaal vindbaar.</h6>
                <ul>
                    <li>Copyright © 2016 - 2021 Zupr BV. Alle rechten voorbehouden.</li>
                </ul>
            </div>
        </div>
    </section>
)
